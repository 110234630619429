<h2 class="title">Bienvenido {{ client?.name }}</h2>
<div class="w-100">
  <p class="precio">
    <span>Precio del agave: </span>{{ this.currentPrice | currency }}
  </p>
</div>
<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Cantidad</th>
      <th scope="col">Rendimiento Esperado</th>
      <th scope="col">Fecha de compra</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of sales">
      <td>{{ item.quantity | number }}</td>
      <td>{{ item.rendimiento }}</td>
      <td>{{ getDate(item.date) | date }}</td>
    </tr>
  </tbody>
</table>

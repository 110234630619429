<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Agregar Venta</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="saleForm">
      <div class="form-group">
        <div>
          <label for="client">Cliente</label>
          <br />
          <div ngbDropdown class="d-inline-block">
            <button
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              {{ selectedClient?.name || "Seleccionar..." }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button
                ngbDropdownItem
                *ngFor="let client of clientsList"
                (click)="setSelectedClient(client)"
              >
                {{ client.name }}
              </button>
            </div>
          </div>
        </div>
        <div>
          <label for="quantity">Cantidad</label>
          <input
            id="quantity"
            type="text"
            class="form-control"
            formControlName="quantity"
          />
        </div>
        <div>
          <label for="rendimiento">Rendimiento Esperado</label>
          <input
            id="rendimiento"
            type="text"
            class="form-control"
            formControlName="rendimiento"
          />
        </div>
        <div>
          <label for="date">Fecha de compra</label>
          <br />
          <div class="input-group">
            <input
              id="date"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              ngbDatepicker
              #d="ngbDatepicker"
              formControlName="date"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar button-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="save()">
      Guardar
    </button>
  </div>
</div>

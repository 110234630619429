import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminHomeComponent } from '../admin-home/admin-home.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;

  constructor(public afAuth: AngularFireAuth, public router: Router) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  get userEmail() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.email;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  get isAdminLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.email === 'admin@admin.com';
  }

  login(email: string, password: string) {
    this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((value) => {
        console.log('Nice, it worked!');
        localStorage.setItem('user', JSON.stringify(value.user));
        if (value.user.email === 'admin@admin.com') {
          this.router.navigateByUrl('/adminhome');
        } else {
          this.router.navigateByUrl('/clienthome');
        }
      })
      .catch((err) => {
        console.log('Something went wrong: ', err.message);
        alert('Correo o contraseña inválidas.');
      });
  }

  emailSignup(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }
}

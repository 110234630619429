<div class="form-container">
  <img src="" alt="" />
  <div class="form-cont">
    <form [formGroup]="loginForm" class="text-center form">
      <input
        type="text"
        placeholder="Email address"
        name="email"
        class="input-txt form-control"
        formControlName="email"
      />
      <br />
      <input
        type="password"
        placeholder="Password"
        name="password"
        class="input-txt form-control"
        formControlName="password"
      />
      <br />
      <button
        type="submit"
        [disabled]="!loginForm.valid"
        class="btn btn-outline-dark"
        (click)="login()"
      >
        Log in
      </button>
    </form>
  </div>
</div>

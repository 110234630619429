<div class="row m-2">
  <div class="col">
    <button class="btn btn-outline-primary" (click)="deleteSelected()">
      Borrar
    </button>
  </div>

  <div class="col text-right">
    <div ngbDropdown class="d-inline-block">
      <button
        class="btn btn-outline-primary"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        Agregar
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="open(content)">Venta</button>
        <button ngbDropdownItem (click)="openClient(client)">
          Nuevo Cliente
        </button>
        <button ngbDropdownItem (click)="changePrice(price)">Precio</button>
      </div>
    </div>
  </div>
</div>

<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Cliente</th>
      <th scope="col">Cantidad</th>
      <th scope="col">Rendimiento Esperado</th>
      <th scope="col">Fecha de compra</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of sells">
      <td>
        <input
          type="checkbox"
          (change)="setSaleSelection($event.target.checked, item)"
        />
      </td>
      <td>{{ item.clientName }}</td>
      <td>{{ item.quantity | number }}</td>
      <td>{{ item.rendimiento }}</td>
      <td>{{ getDate(item.date) | date }}</td>
    </tr>
  </tbody>
</table>

<!-- Modals -->

<!-- Modal sell -->
<ng-template #content let-modal>
  <app-add-sell-modal [modal]="modal"></app-add-sell-modal>
</ng-template>

<!-- Modal client -->
<ng-template #client let-client>
  <app-new-client-modal [modal]="client"></app-new-client-modal>
</ng-template>

<!-- Modal price -->
<ng-template #price let-price>
  <app-price-modal [modal]="price"></app-price-modal>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService) {}

  loginForm = new FormGroup({
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });

  ngOnInit(): void {}

  login() {
    if (this.loginForm.invalid) {
      alert('Faltan datos. Intente nuevamente');
      return;
    } else {
      this.authService.login(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      );
    }
  }
}

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientDataService {
  private dbPath = '/clients';
  itemsRef: AngularFirestoreCollection<any> = null;
  constructor(private db: AngularFirestore) {
    this.itemsRef = db.collection(this.dbPath);
  }
  getAll(): AngularFirestoreCollection<any> {
    return this.itemsRef;
  }

  getUser(email) {
    return this.itemsRef.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
  create(item: any): any {
    return this.itemsRef.add({ ...item });
  }
  update(id: string, data: any): Promise<void> {
    return this.itemsRef.doc(id).update(data);
  }
  delete(id: string): Promise<void> {
    return this.itemsRef.doc(id).delete();
  }
}

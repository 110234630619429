import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PriceServiceService {
  private dbPath = '/price';
  itemsRef: AngularFirestoreCollection<any> = null;
  constructor(private db: AngularFirestore) {
    this.itemsRef = db.collection(this.dbPath);
  }
  getPrice() {
    return this.itemsRef.snapshotChanges().pipe(
      map((actions) => {
        return actions
          .map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          })
          .sort((e1, e2) => {
            if (e1.date < e2.date) return 1;
            else return -1;
          });
      })
    );
  }

  update(id: string, data: any): Promise<void> {
    return this.itemsRef.doc(id).update(data);
  }
}

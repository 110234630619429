import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { ClientDataService } from '../client-data.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
})
export class AdminHomeComponent implements OnInit {
  sells: any;
  selectedSales: any = [];
  clients: any;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
    private clientDataService: ClientDataService
  ) {
    this.dataService.getAll().subscribe((sales) => {
      this.clientDataService
        .getAll()
        .valueChanges()
        .subscribe((clients) => {
          this.clients = clients;

          this.sells = sales.map((currentSale) => {
            let currentClient = this.clients.find(
              (cl) => cl.id === currentSale.client_id
            );
            return {
              ...currentSale,
              clientName: currentClient.name,
            };
          });
        });
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  openClient(client) {
    this.modalService.open(client, { ariaLabelledBy: 'modal-basic-title' });
  }

  setSaleSelection(checked, sale) {
    if (checked) {
      this.selectedSales.push(sale);
    } else {
      this.selectedSales = this.selectedSales.filter(
        (current) => sale !== current
      );
    }
  }

  getDate(date) {
    return new Date(date.seconds * 1000);
  }

  deleteSelected() {
    this.selectedSales.forEach((current) => {
      this.dataService.delete(current.id);
    });
  }

  changePrice(priceModal) {
    this.modalService.open(priceModal);
  }

  ngOnInit(): void {}
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PriceServiceService } from '../price-service.service';

@Component({
  selector: 'app-price-modal',
  templateUrl: './price-modal.component.html',
  styleUrls: ['./price-modal.component.scss'],
})
export class PriceModalComponent implements OnInit {
  @Input() modal: any;
  priceId: string;

  priceForm = new FormGroup({
    price: new FormControl(null, Validators.required),
  });

  constructor(private dataService: PriceServiceService) {}

  ngOnInit(): void {
    this.dataService.getPrice().subscribe((data) => {
      this.priceId = data[0].id;
      this.priceForm.controls.price.setValue(data[0].price);
    });
  }

  savePrice() {
    if (this.priceForm.invalid) {
      alert('Faltan datos. Intente nuevamente');
      return;
    } else {
      this.dataService.update(this.priceId, {
        price: this.priceForm.controls.price.value,
      });
      this.modal.close();
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { LoginComponent } from './admin/login/login.component';
import { AuthGuard } from './auth.guard';
import { ClientGuardGuard } from './client-guard.guard';

const routes: Routes = [
  {
    path: 'adminhome',
    component: AdminHomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'clienthome',
    component: ClientHomeComponent,
    canActivate: [ClientGuardGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { FormsModule } from '@angular/forms';
import { AddSellModalComponent } from './add-sell-modal/add-sell-modal.component';
import { NewClientModalComponent } from './new-client-modal/new-client-modal.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { DataService } from './data.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LoginComponent } from './admin/login/login.component';
import { AuthGuard } from './auth.guard';
import { ClientGuardGuard } from './client-guard.guard';
import { PriceModalComponent } from './price-modal/price-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminHomeComponent,
    ClientHomeComponent,
    AddSellModalComponent,
    NewClientModalComponent,
    LoginComponent,
    PriceModalComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
  ],
  providers: [DataService, AuthGuard, ClientGuardGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientDataService } from '../client-data.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-client-modal',
  templateUrl: './new-client-modal.component.html',
  styleUrls: ['./new-client-modal.component.scss'],
})
export class NewClientModalComponent implements OnInit {
  @Input() modal: any;
  count: number;
  clientForm = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });

  constructor(
    private clientDataService: ClientDataService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.clientDataService
      .getAll()
      .valueChanges()
      .subscribe((clients) => {
        this.count = clients.length;
      });
  }

  save() {
    if (this.clientForm.invalid) {
      alert('Faltan datos. Intente nuevamente');
      return;
    } else {
      let newSale = {
        id: this.count + 1,
        name: this.clientForm.controls.name.value,
        email: this.clientForm.controls.email.value,
        password: this.clientForm.controls.password.value,
      };

      this.authService
        .emailSignup(
          this.clientForm.controls.email.value,
          this.clientForm.controls.password.value
        )
        .then((res) => {
          this.clientDataService.create(newSale);
          this.modal.close();
        });
    }
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBy1fdremb1SIv_7WKPf4A1S6vamEBkkF4',
    authDomain: 'agave-aafe0.firebaseapp.com',
    databaseURL: 'https://agave-aafe0-default-rtdb.firebaseio.com/',
    projectId: 'agave-aafe0',
    storageBucket: 'agave-aafe0.appspot.com',
    messagingSenderId: '454308806552',
    appId: '1:454308806552:web:4bd4d1c76bdd6f14f435ed',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

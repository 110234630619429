<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Cambiar precio del agave al publico.
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="priceForm">
      <div class="form-group">
        <div>
          <input
            id="price"
            type="number"
            class="form-control"
            formControlName="price"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="savePrice()">
      Guardar
    </button>
  </div>
</div>

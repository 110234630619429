<!-- Header -->
<nav class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img
      src="assets/images/logo_a.png"
      width="50"
      height="50"
      class="d-inline-block align-top"
      alt=""
    />
  </a>

  <div class="row">
    <div class="col text-right">
      <a href="" class="cerrar-sesion" (click)="logout()">Cerrar Sesión</a>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<!-- Footer -->

<footer class="text-center text-black bg-light">
  <!-- Grid container -->
  <div class="container p-4"></div>
  <!-- Grid container -->

  <div class="text-center">
    <div class="text-center p-3 bg-white">
      <div>
        <img
          src="assets/images/logo.jpg"
          width="50"
          height="50"
          class="d-inline-block align-top"
          alt=""
        />
        <span class="footer-text">
          <span>Agave 4migos</span>
          <span> • </span>
          <span>San Miguel el Alto Jalisco</span>
        </span>
      </div>
    </div>
  </div>
</footer>

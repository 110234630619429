import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ClientDataService } from '../client-data.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-add-sell-modal',
  templateUrl: './add-sell-modal.component.html',
  styleUrls: ['./add-sell-modal.component.scss'],
})
export class AddSellModalComponent implements OnInit {
  @Input() modal: any;
  clientsList: any;
  selectedClient: any;

  model: NgbDateStruct;

  saleForm = new FormGroup({
    client_id: new FormControl(null, Validators.required),
    quantity: new FormControl(null, Validators.required),
    rendimiento: new FormControl(null, Validators.required),
    date: new FormControl(null, Validators.required),
  });

  constructor(
    private clientDataService: ClientDataService,
    private dataService: DataService
  ) {
    this.clientDataService
      .getAll()
      .valueChanges()
      .subscribe((res) => {
        this.clientsList = res;
      });
  }

  ngOnInit(): void {}

  setSelectedClient(selectedClient) {
    this.selectedClient = selectedClient;
    this.saleForm.controls['client_id'].setValue(selectedClient.id);
  }

  save() {
    if (this.saleForm.invalid) {
      alert('Faltan datos. Intente nuevamente');
      return;
    } else {
      let textDate = `${this.saleForm.controls.date.value.month}/${this.saleForm.controls.date.value.day}/${this.saleForm.controls.date.value.year}`;
      let newSale = {
        client_id: this.saleForm.controls.client_id.value,
        quantity: this.saleForm.controls.quantity.value,
        rendimiento: this.saleForm.controls.rendimiento.value,
        date: new Date(textDate),
      };
      this.dataService.create(newSale);
      this.modal.close();
    }
  }
}

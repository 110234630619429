<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Agregar Cliente</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="clientForm">
      <div class="form-group">
        <div>
          <label for="client">Nombre</label>
          <br />
          <input
            id="client"
            type="text"
            class="form-control"
            formControlName="name"
          />
        </div>
        <div>
          <label for="email">Correo Electrónico</label>
          <br />
          <input
            id="email"
            type="text"
            class="form-control"
            formControlName="email"
          />
        </div>
        <div>
          <label for="client">Contraseña</label>
          <br />
          <input
            id="password"
            type="password"
            class="form-control"
            formControlName="password"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="save()">
      Guardar
    </button>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { DataService } from '../data.service';
import { AuthService } from '../auth/auth.service';
import { ClientDataService } from '../client-data.service';
import { PriceServiceService } from '../price-service.service';

@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.scss'],
})
export class ClientHomeComponent implements OnInit {
  client: any;
  sales: any;
  currentPrice: any;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private clientDataService: ClientDataService,
    private priceServiceService: PriceServiceService
  ) {}

  getDate(date) {
    return new Date(date.seconds * 1000);
  }

  ngOnInit(): void {
    const userEmail = this.authService.userEmail;
    if (userEmail) {
      this.clientDataService
        .getUser(userEmail)
        .pipe(
          filter((clients: any) => {
            let current = clients.find((element) => {
              return element.email === userEmail;
            });

            this.client = current;
            return current;
          })
        )
        .subscribe();
    }
    this.dataService.getAll().subscribe((res) => {
      this.sales = res.filter((item) => {
        return item.client_id == this.client.id;
      });
    });

    this.priceServiceService.getPrice().subscribe((data) => {
      this.currentPrice = data[0].price;
    });
  }
}
